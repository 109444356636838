body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Bebas Neue",
    "TT Hoves Pro Trial Condensed DemiBold", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Open Sans", "Montserrat",
    "Cirka", "Helvetica Neue", "Droid Serif", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
