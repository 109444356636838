//
// footer.scss
//

/*********************************/
/*         Footer                */
/*===============================*/
footer {
  position: relative;
  color: $gray-300;
  background-color: #392415;
  .footer-py-60 {
    padding: 60px 0;
  }
  .footer-py-30 {
    padding: 30px 0;
  }
  .footer-head {
    letter-spacing: 1px;
    font-weight: 500;
    color: $light;
  }

  .text-foot {
    color: $gray-200;
  }
  .logo-footer img {
    max-width: 250px;
  }
  .footer-list {
    margin-bottom: 0;
    li {
      position: relative;
      margin-left: 0;
      margin-bottom: 10px;
      a {
        transition: all 0.5s ease;
        &:hover {
          color: lighten($gray-500, 20%);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-border,
  .footer-bar {
    border-top: 1px solid lighten($secondary, 3%);
  }
  .border {
    border-color: lighten($footer, 3%) !important;
  }
}
