//
// variables.scss
//

// Color
$white: #ffffff;

$primary: #de8f5f;
$secondary: #b7a8e6;
$success: #59cb36;
$warning: #fbbf24;
$info: #8c3061;
$danger: #ff204e;
$dark: #000000;
$black: #161c2d;
$muted: #505864;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #f1f5f9;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

//Body Background
$shadow: 0 0 3px rgba($dark, 0.15);
$shadow-md: 0 5px 13px rgba($dark, 0.2);
$shadow-lg: 0 10px 25px rgba($dark, 0.15);
$footer: lighten($black, 6%);

// Base font
$font-size-base: 18px;

$font-family-base: "TT Hoves Pro Trial Condensed DemiBold", sans-serif;
$font-family-secondary: "Roboto", sans-serif;

//Color Picker/Switcher
$blue: #2146c7;
$purple: #7b2cbf;
$yellow: #facc15;
$red: #dc2626;
$green: #16a34a;
$orange: #f97316;
$rose: #fd9a89;

// Overlay
$overlay: rgba($dark, 0.65);
$linear-gradient: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0) 50%,
  rgba($black, 0.3) 80%,
  rgba($black, 1) 100%
);
$linear-gradient-2: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0.3) 50%,
  rgba($black, 0.7) 75%,
  rgba($black, 1) 100%
);
$gradient-overlay: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0.5) 25%,
  rgba($black, 0.75) 50%,
  rgba($black, 1) 100%
);
$gradient-overlay-2: linear-gradient(
  to bottom,
  rgba($black, 0.3) 0%,
  rgba($black, 0.4) 25%,
  rgba($black, 0.5) 50%,
  rgba($black, 0.6) 100%
);
$primary-gradient-overlay: linear-gradient(
  to bottom,
  rgba($white, 0) 0%,
  rgba($primary, 0.4) 100%
);
$card-overlay: linear-gradient(
  to bottom,
  transparent 0%,
  transparent 25%,
  transparent 35%,
  rgba($black, 0.9) 100%
);

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "footer": $footer,
);

$heading-font-sizes: (
  "h1, .fs-1": 42px,
  "h2, .fs-2": 36px,
  "h3, .fs-3": 30px,
  "h4, .fs-4": 24px,
  "h5, .fs-5": 20px,
  "h6, .fs-6": 17px,
);

$display-font-sizes: (
  "display-1": 80px,
  "display-2": 72px,
  "display-3": 64px,
  "display-4": 56px,
  "display-5": 48px,
  "display-6": 40px,
);
